<template>
    <div class="favorite">
        <div class="leftBox">
            <div class="head_box">
                <div class="head_title">
                    <div class="left_title">
                        <div class="pic_box">
                            <img :src=this.videoList.aweme_cover style="width: 90px;height: 120px;">
                        </div>
                        <div class="data_box">
                            <p>{{this.videoList.aweme_title}}</p>
                            <div class="like">
                                <span>
                                    <i style="margin-right: 5px;" class="iconfont">&#xe62a;</i>
                                    {{this.videoList.digg_count}}
                                </span>
                                <span>
                                    <i style="margin-right: 5px;" class="iconfont">&#xe6e7;</i>
                                    {{this.videoList.share_count}}
                                </span>
                                <span>
                                    <i style="margin-right: 5px;" class="iconfont">&#xe664;</i>
                                    {{this.videoList.comment_count}}
                                </span>
                            </div>
                            <div class="hotWord" style="margin-top: 10px;">
                                <span>热词:</span>
                                <span v-if="this.videoList.hot_words == null">无</span>
                                <span v-else style="margin-left: 10px;"
                                      v-for="(item,index) in this.videoList.hot_words" :key="index">{{item}}
                                </span>
                            </div>
                            <div style="margin-top: 10px;">
                                <span>发布时间：</span>
                                <span>{{this.videoList.aweme_create_time}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="right_button">
                        <el-button @click="jumpToVideo" type="danger">播放视频</el-button>
                    </div>
                </div>
            </div>
            <div style="background-color: #F3F3F6;height: 20px;width: 100%"></div>
            <div class="tab_box">
                <el-tabs v-model="ShortTab" type="card" @tab-click="handleClick">
                    <el-tab-pane label="视频详情" name="first">
                        <authorVideoDetail v-if="this.ShortTab == 'first'" :aweme_id="this.aweme_id">
                        </authorVideoDetail>
                    </el-tab-pane>
                    <el-tab-pane label="视频观众分析" name="second">
                        <authorVideoFans v-if="this.ShortTab == 'second'" :aweme_id=this.aweme_id>
                        </authorVideoFans>
                    </el-tab-pane>
                    <el-tab-pane label="商品分析" name="third">
                        <authorProductAnalysis v-if="this.ShortTab == 'third' " @aweme_id="handleBack" @activename="handleBack1" :aweme_id=this.aweme_id>
                        </authorProductAnalysis>
                    </el-tab-pane>
                    <el-tab-pane label="电商视频热度监控" name="fourth">
                        <authorMonitoring v-if="this.ShortTab == 'fourth'" :aweme_id=this.aweme_id>
                        </authorMonitoring>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <!--右侧帮助中心-->
        <RightHelpList></RightHelpList>
    </div>
</template>

<script>
    import authorVideoDetail from '../../../components/mediumModule/authorVideoDetail'
    import authorVideoFans from "../../../components/mediumModule/authorVideoFans";
    import authorProductAnalysis from "../../../components/mediumModule/authorProductAnalysis";
    import authorMonitoring from "../../../components/mediumModule/authorMonitoring";
    import RightHelpList from '@/components/helpCenter/RightHelpList.vue'

    export default {
        name: "VideoDetail",
        components: {
            authorVideoDetail,
            authorVideoFans,
            authorProductAnalysis,
            authorMonitoring,
            RightHelpList
        },
        data() {
            return {
                ShortTab: this.$route.query.activename || 'first',
                videoList: {},//视频详情
                activename: "",
                aweme_id: "",
            }
        },
        created() {
            this.aweme_id = this.$route.query.aweme_id;
            this.getVideoList();
        },
        methods: {
            //打开视频
            jumpToVideo() {
                window.open(this.videoList.aweme_url);
            },
            getVideoList() {
                this.$httpStudent.axiosGetBy(this.$api.hotPeopleVideoDetails, {aweme_id: this.aweme_id,}, res => {
                    if (res.code == 200) {
                        this.videoList = res.data.video_details.aweme;
                    } else {
                        this.$message({
                            type: "error",
                            message: res.msg,
                            duration: 1000
                        });
                    }
                });
            },
            handleClick(tab, event) {
            },
            handleBack(val){
                this.aweme_id = val;
                this.getVideoList();
                console.log(val,678)
            },
            handleBack1(val){
                this.ShortTab = val;
                console.log(val,876)
            }
        }
    }
</script>

<style scoped lang="scss">
    .favorite {
        height: 100%;
        display: flex;

        .leftBox {
            width: 80%;
            display: flex;
            flex-direction: column;

            .head_box {
                width: 100%;
                height: 160px;
                background-color: #FFFFFF;

                .head_title {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    .left_title {
                        display: flex;

                        .pic_box {
                            padding: 20px;
                        }

                        .data_box {
                            padding: 10px 0;

                            .like {
                                display: flex;
                                font-size: 14px;
                                font-weight: 400;

                                span {
                                    display: flex;
                                    justify-content: start;
                                    align-items: center;
                                    margin-right: 15px;
                                }
                            }

                            .hotWord {
                                /*.el-tag {*/
                                /*    background: #f4f4f4;*/
                                /*    border-radius: 12px;*/
                                /*    border: 1px solid #f4f4f4;*/
                                /*    height: 24px;*/
                                /*    line-height: 24px;*/
                                /*    font-size: 12px;*/
                                /*    font-family: Microsoft YaHei;*/
                                /*    font-weight: 400;*/
                                /*    color: #333;*/
                                /*    padding: 0 15px;*/
                                /*    margin-left: 5px;*/
                                /*}*/
                            }
                        }
                    }

                    .right_button {
                        padding: 20px;
                    }
                }
            }

            .tab_box {
                display: flex;
                flex: 1;
                height: 1%;
                margin-top: 20px;

                ::v-deep .el-tabs {
                    height: 100%;
                    width: 100%;
                    box-shadow: none;
                    border: none;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    background: transparent;
                }

                ::v-deep .el-tabs__header {
                    border-bottom: none;
                    margin: 0;
                }

                ::v-deep .el-tabs__content {
                    height: 1%;
                    flex: 1;
                    background: #fff;
                    padding-top: 20px;
                    min-height: 600px;
                }

                ::v-deep .el-tabs__header .is-active {
                    background: #fff;
                    font-size: 16px;
                    font-weight: 400;
                    color: #0824ce;
                    border-radius: 6px 6px 0 0;
                }

                ::v-deep .el-tabs__item {
                    border: none;
                    height: 52px;
                    line-height: 52px;
                }

                ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
                    border: none;
                }

                ::v-deep .el-tab-pane {
                    height: 100%;
                }
            }
        }
    }

</style>