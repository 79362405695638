<template>
    <div class="contain_box">
        <div class="head_title">
            <div class="left_title">
                <div class="pic_box"></div>
                <div class="data_box" v-if="this.videoData.title">
                    <p style="cursor: pointer;" @click="jumpToGoods">{{this.videoData.title}}</p>
                    <div>
                        <span style="color: #FF0000">￥</span>
                        <span style="font-size:24px;font-weight:bold;color: #FF0000;">{{this.videoData.final_price}}</span>
                        <span style="margin-left: 10px;text-decoration:line-through">原价:￥{{this.videoData.max_price}}</span>
                    </div>
                    <div style="margin-top: 10px;">
                        <span>抖音浏览量:{{this.videoData.pv_count}}</span>
                        <span style="margin-left: 10px;">全网销量：{{this.videoData.order_count}}</span>
                    </div>
                </div>
                <div v-else>暂无数据</div>
            </div>
        </div>
        <div class="divider">
            <el-divider></el-divider>
        </div>
        <div class="middle_box">
            <div style="margin-left: 40px;">相关商品推广视频</div>
        </div>
        <div class="divider">
            <el-divider></el-divider>
        </div>
        <div class="main_box">
            <el-table :show-header="false" :data="tableData" style="width: 100%">
                <el-table-column prop="aweme_info" label="视频封面" width="600">
                    <template slot-scope="scope">
                        <div class="videoBox" style="display: flex;cursor: pointer;"
                             @click="jumpToVideoDetail(scope.row)">
                            <div class="picBox">
                                <img style="width: 90px;height: 120px;" :src=scope.row.aweme_info.aweme_cover>
                            </div>
                            <div style="padding: 20px 0 10px 20px;">
                                <div style="font-size:14px;">{{scope.row.aweme_info.aweme_title}}</div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="aweme_info" label="作者" width="300">
                    <template slot-scope="scope">
                        <div class="videoBox" style="display: flex;cursor: pointer;" @click="jumpToAuthor(scope.row)">
                            <div class="picBox">
                                <img style="width: 40px;height: 40px;border-radius: 50%"
                                     :src=scope.row.author_info.avatar>
                            </div>
                            <div style="padding: 0 0 10px 10px;">
                                <div style="font-size:14px;">{{scope.row.author_info.nickname}}</div>
                                <div style="font-size:14px;">{{scope.row.aweme_info.aweme_create_time}}</div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="aweme_info" fixed="right" width="180" label="数据">
                    <template slot-scope="scope">
                        <div style="display: flex;">
                            <div>
                                <i style="margin-right: 2px;" class="iconfont">&#xe62a;</i>
                                <span>{{scope.row.aweme_info.digg_count}}</span>
                            </div>
                            <div style="margin-left: 5px;">
                                <i style="margin-right: 2px;" class="iconfont">&#xe6e7;</i>
                                <span>{{scope.row.aweme_info.share_count}}</span>
                            </div>
                            <div style="margin-left: 5px;">
                                <i style="margin-right: 4px;margin-top: 1px;" class="iconfont">&#xe664;</i>
                                <span>{{scope.row.aweme_info.comment_count}}</span>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
    export default {
        props: ["aweme_id"],
        data() {
            return {
                videoData: {},
                tableData: [],
                id: "",
            }
        },
        created() {
            this.getVideoDetail();
        },
        methods: {
            getVideoDetail() {
                let param = {
                    aweme_id: this.aweme_id ? this.aweme_id : this.id
                };
                this.$httpStudent.axiosGetBy(this.$api.hotPeopleVideoDetails, param, res => {
                    if (res.code == 200) {
                        this.videoData = res.data.video_goods;
                        this.tableData = res.data.goods_video_list;

                    } else {
                        this.$message({
                            type: "error",
                            message: res.msg,
                            duration: 1000
                        });
                    }
                });
            },
            jumpToGoods() {
                window.open(this.videoData.url);
            },
            jumpToVideoDetail(row) {
                this.$emit("aweme_id", row.aweme_info.aweme_id);
                this.$emit("activename", "first");
            },
            jumpToAuthor(row) {
                this.$router.push({
                    path: "/student/hot/authorDetail",
                    query: {
                        author_id: row.author_info.author_id,
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .contain_box {
        height: 100%;

        .head_title {
            height: 80px;
            display: flex;
            justify-content: space-between;

            .left_title {
                margin-left: 40px;
            }
        }

        .divider {
            padding: 5px 20px 0 20px;
        }

        .main_box {
            padding-top: 20px;
            margin-left: 40px;
        }
    }
</style>